import React from "react";
import { cn } from "src/helpers/bem";
import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import "./Steps.scss";
import { Steps as AntdSteps } from "antd";
import { ReactComponent as FinishIcon } from "./images/finish-icon.svg";
import { isDefaultSmevBehavior, isScene } from "src/helpers";
import { IProps } from "./interface";

const { Step } = AntdSteps;

const b = cn("site-steps");

const GET_KEP_STEP_INDEX = 1;

export const Steps = (props: IProps) => {
  const { steps, currentStep, currentScene, activationLink, userCompanyId } = props;

  return (
    <AntdSteps direction="vertical" size="small" current={currentStep} className={b()}>
      {steps.map((stepData, index) => {
        let stepClassName = "";
        const loaderVisible =
          (isScene("Подписание", currentScene) ||
            (isScene("Создание ссылки", currentScene) && activationLink) ||
            isScene("Проверка СМЭВ", currentScene)) &&
          currentStep === index;

        if (index === steps.length - 1 && isScene("Зарегистрирован", currentScene)) {
          stepClassName = b("last-step");
        } else if (loaderVisible) {
          stepClassName = b("loading-step");
        } else if (index === GET_KEP_STEP_INDEX && isScene("Ошибка СМЭВ", currentScene)) {
          stepClassName = b("failed-step", { warning: !isDefaultSmevBehavior(userCompanyId) });
        }

        let stepIcon;

        if (index < currentStep || (index === steps.length - 1 && isScene("Зарегистрирован", currentScene))) {
          stepIcon = <FinishIcon />;
        } else if (loaderVisible) {
          stepIcon = <LoadingOutlined className={b("loader-icon")} />;
        } else if (index === GET_KEP_STEP_INDEX && isScene("Ошибка СМЭВ", currentScene)) {
          stepIcon = <ExclamationCircleFilled />;
        }
        return (
          <Step
            title={stepData.title}
            description={index === currentStep ? stepData.description : ""}
            subTitle={stepData.subTitle}
            key={`step${index}`}
            className={stepClassName}
            icon={stepIcon}
          />
        );
      })}
    </AntdSteps>
  );
};
