const МодульБанкId = 307;
const БизнесКлассId = 261;
const UrvistaId = 4;
const БланкБанкId = 308;
const БланкБанкId2 = 320;

export const COMPANIES_IDS_THAT_CAN_VIEW_MARKETPLACE = [1, 4];
export const COMPANIES_IDS_THAT_CAN_UPLOAD_FORM = [1, 4, 261, 280];
export const ADMINS_ID = [1];

export const COMPANIES_IDS_THAT_CAN_CALL_COURIER = [1, МодульБанкId];
export const COMPANIES_IDS_THAT_HAVE_DEFAULT_SMEV_BEHAVIOR = [МодульБанкId, БланкБанкId, БланкБанкId2];
