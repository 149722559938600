import { entriesConstants, getEntry } from "src/redux/entries";
import { CheckActivationResponse, CheckActivationStatus } from "src/helpers/types/ResponsesFromBackend";
import { endpoints, SMEV_POOLING_INTERVAL } from "src/constants";
import { API } from "src/helpers";
import { message } from "antd";

export const smevPooling = (entryId: number, entryType: string) => {
  const {
    ENTRY_SMEV_POOLING_REQUEST,
    ENTRY_SMEV_POOLING_SUCCESS,
    ENTRY_SMEV_POOLING_FAILURE,
    ENTRY_SMEV_POOLING_ONLY_PASSPORT_LEFT,
  } = entriesConstants;

  const request = (smevPoolingDescriptor: NodeJS.Timer) => ({
    type: ENTRY_SMEV_POOLING_REQUEST,
    smevPoolingDescriptor,
  });
  const success = () => ({ type: ENTRY_SMEV_POOLING_SUCCESS });
  const onlyPassportLeft = () => ({ type: ENTRY_SMEV_POOLING_ONLY_PASSPORT_LEFT });
  const failure = () => ({ type: ENTRY_SMEV_POOLING_FAILURE });

  return async function (dispatch: Function) {
    const POOLING_MAX_COUNT = 100;
    let currentPoolingIteration = 0;
    let smevPoolingDescriptor: NodeJS.Timer;

    async function checkSmevActivationPooling() {
      async function checkSmevActivationStep() {
        currentPoolingIteration++;
        if (currentPoolingIteration >= POOLING_MAX_COUNT) {
          await dispatch(getEntry(entryId));
          dispatch(failure());
        }

        try {
          const checkActivationResponse: CheckActivationResponse = await API.get(
            `${endpoints.entries.checkActivation}/${entryId}`
          );
          processSmevPoolingResponse(checkActivationResponse);
        } catch (e) {
          // еще один try catch, потому что в "e" может быть что-то, что нельзя скормить в JSON.parse
          try {
            processSmevPoolingResponse(JSON.parse(e as any));
          } catch (e) {}
        }
      }

      checkSmevActivationStep();
      smevPoolingDescriptor = setInterval(checkSmevActivationStep, SMEV_POOLING_INTERVAL);
      dispatch(request(smevPoolingDescriptor));

      async function processSmevPoolingResponse(response: CheckActivationResponse) {
        if (response.check_status === CheckActivationStatus.FAILED) {
          await dispatch(getEntry(entryId));
          dispatch(failure());
        } else if (response.check_status === CheckActivationStatus.WAITING) {
          // в рамках проверки СМЭВ осталось проверить только паспорт
          const onlyPassportCheckLeft = Object.values(response.not_activated).every((docs) =>
            docs.every((it) => it === "passport")
          );
          if (onlyPassportCheckLeft) {
            dispatch(onlyPassportLeft());
          }
        } else if (response.check_status === CheckActivationStatus.SUCCESS) {
          await dispatch(getEntry(entryId));
          dispatch(success());
        }
      }
    }

    try {
      await checkSmevActivationPooling();
    } catch (e) {
      message.destroy();
      message.error(`Ошибка при проверке СМЭВ - ${JSON.stringify(e)}`);
      dispatch(failure());
    }
  };
};
