import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import classNames from "classnames/dedupe";
import { Divider, Modal, message } from "antd";
import QueueAnim from "rc-queue-anim";
import { LoadingOutlined } from "@ant-design/icons";
import { addOtherFile } from "src/redux/files";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes, formsNames, errorsTypes, equityFormatToAttr } from "src/constants";
import { mainFields, ownerFileFields, addressFieldsNames } from "./constants";
import { addOwner, removeOwner, updateOwner, updateEIO } from "src/redux/entries";
import { COMPANIES_IDS_THAT_CAN_CALL_COURIER } from "src/constants/permissions";
import { Tooltip, UserPreview, UploadLink, CopyToClipboardButton } from "src/components";
import "./Forms.scss";
import {
  getSurnameAndInitials,
  isScene,
  customBuildingInfoValidate,
  isAdmin,
  canUserUploadForm,
  isDefaultSmevBehavior,
} from "src/helpers";
import {
  RegistrationForm,
  FilesForm,
  CommonOOOForm,
  OwnerForm,
  EIOForm,
  CommonForm,
  PassportForm,
  ActivityForm,
  DocsFormatForm,
  DeliveryForm,
} from "src/forms";
import { ReactComponent as CheckIcon } from "../images/check-icon.svg";
import { AppState } from "src/redux/AppState";
import { IProps, IState } from "./interface";
import { IOwner } from "src/helpers/types/Owner";
import { IExecutive } from "src/helpers/types/Executive";

const sf = cn("site-forms");
const b = cn("OOOregistration-forms");

const getOwnerById = (id: number, owners: Array<IOwner>) => owners.filter((it) => it.id === id)[0];

class Forms extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ownerModalVisible: {},
      EIOModalVisible: false,
      currentOwnerId: undefined,
    };
  }

  handleOwnerModal = (id: number) => {
    const ownerModalVisible = Object.assign({}, this.state.ownerModalVisible);
    ownerModalVisible[id] = !ownerModalVisible[id];
    this.setState({
      ownerModalVisible,
      currentOwnerId: typeof id !== "undefined" ? id : this.state.currentOwnerId,
    });
  };

  handleEIOModal = () => {
    this.setState({ EIOModalVisible: !this.state.EIOModalVisible });
  };

  getOwnerStatus = () => {
    /* пока что нельзя отследить, какой учредитель подписал, а какой - нет,
    поэтому на данном этапе выводим статусы в зависимости от текущей сцены */
    const { scene } = this.props;

    if (isScene("Создание ссылки", scene)) {
      return (
        <div className={b("owner-status")}>
          <CheckIcon /> Выпущена эл. подпись
        </div>
      );
    }
    if (isScene("Подписание", scene)) {
      return (
        <div className={b("owner-status")}>
          <LoadingOutlined className={b("loader-icon")} /> Подписание
        </div>
      );
    }
    if (isScene("Активация КЭП", scene)) {
      return (
        <div className={b("owner-status")}>
          <CheckIcon /> Выпущена КЭП
        </div>
      );
    }
    if (isScene("Подписано", scene)) {
      return (
        <div className={b("owner-status")}>
          <CheckIcon /> Подписано
        </div>
      );
    }

    return undefined;
  };

  renderOwnerPreview = (data: IOwner) => {
    const { validateErrors, disabled, removeOwner, scene, formattedEntry, equity_format } = this.props;
    const { ownerErrorIds } = validateErrors;
    const { id: ownerId, equity_cost } = data;
    //@ts-ignore
    const link = formattedEntry && formattedEntry[`${attributes["ссылкаНаПодписаниеУчредителя"]}_${ownerId}`];

    /* Формируем массив данных для отображения в блоке учредителя через вертикальную черту. 
    Некоторые данные могут быть undefined, в таком случае удаляем их из массива */
    let previewData = [
      equity_cost && parseFloat(equity_cost).toLocaleString() + " ₽",
      //@ts-ignore
      data[equityFormatToAttr[equity_format]],
      this.getOwnerStatus(),
    ];
    previewData = previewData.filter((it) => typeof it !== "undefined");

    return (
      <div className={b("owner-section")} key={`owner-preview-${ownerId}`}>
        <UserPreview
          label={getSurnameAndInitials(data) || "Новый учредитель"}
          className={b("owner-preview")}
          options={previewData}
          onEdit={() => this.handleOwnerModal(ownerId)}
          onRemove={() => removeOwner(ownerId)}
          disabled={disabled}
          errorVisible={ownerErrorIds && ownerErrorIds.some((id: number) => id === ownerId)}
        />
        {isScene("Подписание", scene) && (
          <div className={b("signing")}>
            <div className={b("signing-wrapper")}>
              <p className={b("signing-link")}>{link}</p>
              <CopyToClipboardButton textToCopy={link} />
            </div>
          </div>
        )}
      </div>
    );
  };

  renderOwnerModal = (data: IOwner) => {
    const {
      loading,
      wasLoaded,
      scene,
      disabled,
      updateOwner,
      filesEnabled,
      userCompanyId,
      currentEntryId,
      formattedEntry,
    } = this.props;
    const { currentOwnerId } = this.state;
    const ownerModalVisible = Object.assign({}, this.state.ownerModalVisible);
    const { id } = data;

    return (
      <Modal
        visible={ownerModalVisible[id]}
        footer={null}
        onCancel={() => {
          updateOwner(currentOwnerId!);
          this.handleOwnerModal(id);
        }}
        title="Данные учредителя"
        className="common-modal"
        forceRender={true}
        key={`modal${id}`}
      >
        <div className={sf("modal-row")}>
          <div className={sf("modal-wrapper")}>
            <CommonForm
              data={data}
              form={`${formsNames["common"]}-${formsNames["owner"]}${id}`}
              showNalog={false}
              disabled={disabled}
              key={`common${id}`}
              shortEmail={true}
            />
            {typeof userCompanyId === "number" && COMPANIES_IDS_THAT_CAN_CALL_COURIER.includes(userCompanyId) && (
              <>
                <Divider />
                <DeliveryForm
                  data={data}
                  form={`${formsNames["delivery"]}-${formsNames["owner"]}${id}`}
                  disabled={disabled}
                />
              </>
            )}
            <Divider />
            <OwnerForm data={data} form={`${formsNames["owner"]}${id}`} disabled={disabled} key={`owner${id}`} />
          </div>
          <div>
            <FilesForm
              showTitle={false}
              fileFields={ownerFileFields.map((fileInfo) => ({
                ...fileInfo,
                params: { n: id },
              }))}
              disabled={
                !(
                  (
                    isScene("Создание ссылки", scene) ||
                    isScene("Проверка СМЭВ", scene) ||
                    isScene("Активация КЭП", scene)
                  )
                  // подробнее тут - https://github.com/iamttfttmutmaw/buro_front/issues/427
                ) &&
                !(isScene("Ошибка СМЭВ", scene) && !isDefaultSmevBehavior(userCompanyId!)) &&
                !filesEnabled
              }
              disabledTooltipVisible={isScene("Заполнение", scene)}
              key={`files${id}`}
              loading={loading}
              wasLoaded={wasLoaded}
            />
            {isScene("Выпущена КЭП", scene) && (
              <UploadLink
                id={currentEntryId}
                //@ts-ignore
                identificationId={formattedEntry[`${attributes["токенДляСсылкиЗагрузкиФайлов"]}_${id}`]}
                narrow
                ownerNum={id}
              />
            )}
          </div>
        </div>
        <Divider />
        <PassportForm
          data={data}
          allowForeign={false}
          form={`${formsNames["passport"]}-${formsNames["owner"]}${id}`}
          disabled={disabled}
          key={`passport${id}`}
        />
        <Divider />
        <RegistrationForm
          data={data}
          form={`${formsNames["registration"]}-${formsNames["owner"]}${id}`}
          disabled={disabled}
          key={`registration${id}`}
          showRegistrOrgan={false}
        />
      </Modal>
    );
  };

  renderEIOPreview = (data: IExecutive) => {
    const { validateErrors, disabled } = this.props;
    const { eioError } = validateErrors;
    const { executive_title } = data;

    let previewData = [executive_title];
    previewData = previewData.filter((it) => typeof it !== "undefined");

    return (
      <UserPreview
        label={getSurnameAndInitials(data) || "Новый исполнительный орган"}
        options={previewData}
        onEdit={this.handleEIOModal}
        disabled={disabled}
        errorVisible={eioError}
      />
    );
  };

  renderEIOModal = (data: Record<string, any>) => {
    const { updateEIO, disabled } = this.props;
    const { EIOModalVisible } = this.state;
    return (
      <Modal
        visible={EIOModalVisible}
        footer={null}
        onCancel={() => {
          updateEIO();
          this.handleEIOModal();
        }}
        title="Данные исполнительного органа"
        className="common-modal"
        forceRender={true}
      >
        <CommonForm
          data={data}
          form={`${formsNames["common"]}-${formsNames["eio"]}`}
          showPhone={false}
          showNalog={false}
          disabled={disabled}
        />
        <Divider />
        <EIOForm data={data} form={`${formsNames["eio"]}`} disabled={disabled} />
        <Divider />
        <PassportForm
          data={data}
          showSnils={false}
          allowForeign={false}
          form={`${formsNames["passport"]}-${formsNames["eio"]}`}
          disabled={disabled}
        />
        {/*<Divider />
         <RegistrationForm
          data={data}
          form={`${formsNames["registration"]}-${formsNames["eio"]}`}
          disabled={disabled}
          showRegistrOrgan={false}
        /> */}
      </Modal>
    );
  };

  renderFilesForm = () => {
    const {
      loading,
      wasLoaded,
      scene,
      filesEnabled,
      formattedEntry,
      firstOtherFile,
      otherFiles,
      loadedOtherFilesCount,
      addOtherFile,
      ustav_checkbox,
      userCompanyId,
    } = this.props;

    //@ts-ignore
    const owners: IOwner[] = formattedEntry[attributes["учредители"]] || [];
    const otherFilesFields = [];
    let finalFilesFields = mainFields.slice();

    // Если загружен файл 'file_drug', то отображаем его только на этапе
    // после отправки в ФНС
    if (firstOtherFile && (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene))) {
      otherFilesFields.push({
        attribute: attributes["Другое"],
        title: "Другое",
        label: "Другое",
        fileType: "uploadIP_EDIT",
      });
    }

    if (otherFiles.length > 0) {
      otherFiles.forEach((file) => {
        otherFilesFields.push({
          attribute: file,
          title: "Другое",
          label: "Другое",
          fileType: "uploadIP_EDIT",
        });
      });
    }
    if (otherFiles.length === loadedOtherFilesCount) {
      addOtherFile(attributes["Другое"]);
    }

    // Если выбран типовой устав, убрать возможность загрузки Устава
    if (ustav_checkbox) {
      finalFilesFields = finalFilesFields.filter((field) => field.attribute !== attributes["Устав"]);
    }

    finalFilesFields = finalFilesFields.concat(otherFilesFields);

    return (
      <div>
        <FilesForm
          fileFields={finalFilesFields}
          disabled={
            !(
              (isScene("Создание ссылки", scene) || isScene("Проверка СМЭВ", scene) || isScene("Активация КЭП", scene))
              // подробнее тут - https://github.com/iamttfttmutmaw/buro_front/issues/427
            ) &&
            !(isScene("Ошибка СМЭВ", scene) && !isDefaultSmevBehavior(userCompanyId!)) &&
            !filesEnabled
          }
          disabledTooltipVisible={isScene("Заполнение", scene)}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        {/* на этапе после выпуска КЭП нужно дать возможность загрузить файл формы P11001 */}
        {typeof userCompanyId === "number" && canUserUploadForm(userCompanyId) && isScene("Создание ссылки", scene) ? (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={[
                {
                  attribute: "11001",
                  title: "Форма P11001",
                  label: "Форма P11001",
                  accept: "tiff",
                  fileType: "uploadOOO",
                },
              ]}
              showTitle={false}
              loading={loading}
              wasLoaded={wasLoaded}
            />
          </>
        ) : null}

        {/* на этапе после выпуска КЭП необходимо добавить поля для загрузки
        скана сертификата и фото клиента для каждого учредителя, 
        под основными файлами, через разделитель */}
        {isScene("Выпущена КЭП", scene) &&
          owners.map((it) => {
            const { id } = it;
            const currentOwner = getOwnerById(id, owners);
            const ownerFilesFields = [
              {
                attribute: attributes["Сертификат"],
                title: (
                  <>
                    Скан сертификата
                    <br />
                    {getSurnameAndInitials(currentOwner)}
                  </>
                ),
                label: "Сертификат",
                accept: "pdfOrImage",
                fileType: "uploadOOO",
                params: { n: id?.toString() },
              },
              {
                attribute: attributes["Фото клиента"],
                title: (
                  <>
                    Фото клиента
                    <br />
                    {getSurnameAndInitials(currentOwner)}
                  </>
                ),
                label: "Фото",
                accept: "image",
                fileType: "uploadOOO",
                params: { n: id?.toString() },
              },
            ];
            return (
              <>
                <Divider className={sf("files-divider")} />
                <FilesForm
                  fileFields={ownerFilesFields}
                  disabled={isScene("Зарегистрирован", scene) || isScene("Регистрация в ФНС", scene)}
                  disabledTooltipVisible={isScene("Заполнение", scene)}
                  showTitle={false}
                  loading={loading}
                  wasLoaded={wasLoaded}
                />
              </>
            );
          })}
      </div>
    );
  };

  render() {
    const {
      disabled,
      formattedEntry,
      addOwner,
      validateErrors,
      scene,
      addressEnabled,
      creationDateEnabled,
      userCompanyId,
    } = this.props;
    //@ts-ignore
    const owners: IOwner[] = formattedEntry[attributes["учредители"]] || [];
    const { ownersError } = validateErrors;

    if (!formattedEntry) {
      return <div />;
    }
    //@ts-ignore
    const executives = formattedEntry[attributes["ЕИО"]] || {};
    // просто disabled={disabled} у ActivityForm почему-то не работает
    const activityDisabledKostil = {
      disabled,
    };

    const MAX_OWNER_COUNT = typeof userCompanyId === "number" && isAdmin(userCompanyId) ? 100 : 4;

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          <CommonOOOForm
            data={formattedEntry}
            creationDateEnabled={creationDateEnabled}
            disabled={disabled}
            scene={scene}
          />

          <h2 className={sf("title")}>Учредители</h2>
          {!isScene("Выпущена КЭП", scene) && (
            <div className={b("button-wrapper")}>
              <button
                type="button"
                className={`${sf(
                  "add-owner"
                )} custom-button custom-button_long custom-button_large custom-button_faded`}
                onClick={
                  owners?.length < MAX_OWNER_COUNT
                    ? addOwner
                    : () => message.error("В данный момент можно добавить только четыре учредителя")
                }
                disabled={disabled}
              >
                Добавить учредителя
              </button>
              <Tooltip title={errorsTypes.requiredOwners} visible={ownersError} />
            </div>
          )}
          {owners?.length > 0 && (
            <>
              <div className={classNames(sf("owners"), b("owners-wrapper"))}>
                <QueueAnim>
                  {owners.map((it) => {
                    const currentOwner = getOwnerById(it.id, owners);
                    return this.renderOwnerPreview(currentOwner);
                  })}
                </QueueAnim>
              </div>
              {owners.map((it) => {
                const currentOwner = getOwnerById(it.id, owners);
                return this.renderOwnerModal(currentOwner);
              })}
            </>
          )}
          <DocsFormatForm disabled={disabled} data={formattedEntry} className={b("docs-form")} />

          <Divider />

          <h2 className={sf("title")}>Исполнительный орган</h2>
          {this.renderEIOPreview(executives)}
          {this.renderEIOModal(executives)}

          <Divider />

          <ActivityForm {...activityDisabledKostil} disabled={disabled} scene={scene} showPrintButton />

          <Divider />

          <RegistrationForm
            form={formsNames["OOOregistration"]}
            data={formattedEntry}
            disabled={disabled && !addressEnabled}
            showFinalAddress={true}
            title="Адрес местонахождения"
            fieldsNames={addressFieldsNames}
            extended
            customValidate={customBuildingInfoValidate}
          />
        </div>

        {this.renderFilesForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded, formattedEntry, currentEntry, validateErrors } = state.entries;
  const user = state?.userReducer?.user;
  const userCompanyId = user?.company?.id;
  const parentCopy = currentEntry?.parentCopy;
  const currentEntryId = currentEntry?.id;
  const filesEnabled = typeof parentCopy !== "undefined";
  const creationDateEnabled = typeof parentCopy !== "undefined";
  const addressEnabled = parentCopy === "address";
  const equity_format = formValueSelector(formsNames["commonOOO"])(
    state,
    attributes["Вид указания размера уставного капитала"]
  );
  const ustav_checkbox = formValueSelector(formsNames["commonOOO"])(
    state,
    attributes["Общество использует типовой Устав"]
  );
  const firstOtherFile = formattedEntry?.file_drug;
  const otherFiles = state.files.otherFiles.files;
  const loadedOtherFilesCount = Object.entries(state.files.loaded).filter(
    ([key, value]) => key.match(/drug(13|14)?_\d/g) && typeof value !== "undefined"
  ).length;

  return {
    loading,
    wasLoaded,
    formattedEntry,
    validateErrors,
    filesEnabled,
    addressEnabled,
    creationDateEnabled,
    equity_format,
    ustav_checkbox,
    firstOtherFile,
    otherFiles,
    loadedOtherFilesCount,
    userCompanyId,
    currentEntryId,
  };
};

const mapDispatchToProps = {
  addOwner,
  removeOwner,
  updateOwner,
  updateEIO,
  addOtherFile,
};

// @ts-ignore
const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Forms);

export { connectedComponent as Forms };
