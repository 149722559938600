import React, { Component } from "react";
import { cn } from "src/helpers/bem";
import { Divider, Checkbox, Modal } from "antd";
import { UserPreview, Tooltip } from "src/components";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { attributes, entriesTypes, errorsTypes } from "src/constants";
import { COMPANIES_IDS_THAT_CAN_CALL_COURIER } from "src/constants/permissions";
import { mainFields, additionalFields, fileFieldsModal } from "./constants";
import "./Forms.scss";
import { getSurnameAndInitials, isDefaultSmevBehavior, isScene } from "src/helpers";
import {
  CommonForm,
  FilesForm,
  ActivityForm,
  INNandOGRNForm,
  PassportForm,
  RegistrationForm,
  DocsFormatForm,
  DeliveryForm,
} from "src/forms";
import { changeFormattedEntryAttribute, updateApplicant } from "src/redux/entries";
import { addOtherFile } from "src/redux/files";
import { AppState } from "src/redux/AppState";
import { IProps } from "./interface";
import { IFileField } from "src/helpers/types/Files";

const sf = cn("site-forms");
const b = cn("changeIp_forms");

class Forms extends Component<IProps> {
  state = { modalVisible: false };

  handleModal = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };

  renderPreview = () => {
    const { formattedEntry, disabled, validateErrors } = this.props;
    const applicantError = validateErrors?.applicantError;

    return (
      <UserPreview
        onEdit={this.handleModal}
        label={getSurnameAndInitials(formattedEntry) || "Новый заявитель"}
        disabled={disabled}
        errorVisible={Boolean(applicantError)}
      />
    );
  };

  renderModal = () => {
    const { loading, wasLoaded, formattedEntry, disabled, scene, updateApplicant, userCompanyId } = this.props;
    const { modalVisible } = this.state;

    return (
      <Modal
        visible={modalVisible}
        footer={null}
        onCancel={() => {
          updateApplicant();
          this.handleModal();
        }}
        title="Данные заявителя"
        className="common-modal"
        forceRender={true}
      >
        <CommonForm data={formattedEntry} disabled={disabled} showNalog={false} />

        {typeof userCompanyId === "number" && COMPANIES_IDS_THAT_CAN_CALL_COURIER.includes(userCompanyId) && (
          <>
            <Divider />
            <DeliveryForm disabled={disabled} data={formattedEntry} />
          </>
        )}

        <Divider />
        <FilesForm
          showTitle={false}
          fileFields={fileFieldsModal}
          disabled={
            !(
              (isScene("Создание ссылки", scene) || isScene("Проверка СМЭВ", scene) || isScene("Активация КЭП", scene))
              // подробнее тут - https://github.com/iamttfttmutmaw/buro_front/issues/427
            ) && !(isScene("Ошибка СМЭВ", scene) && !isDefaultSmevBehavior(userCompanyId!))
          }
          disabledTooltipVisible={isScene("Заполнение", scene)}
          className={`${sf("files-row")} ${b("files-row")}`}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        <Divider />
        <PassportForm data={formattedEntry} allowForeign={false} disabled={disabled} />
        <Divider />
        <RegistrationForm data={formattedEntry} disabled={disabled} showRegistrOrgan={false} />
      </Modal>
    );
  };

  renderCheckbox = () => {
    const { OKVEDChanged, changeFormattedEntryAttribute, changeOKVEDError, disabled } = this.props;

    return (
      <div className={sf("checkbox-wrapper")}>
        <Checkbox
          onChange={(e) => changeFormattedEntryAttribute(attributes["изменениеОКВЭД"], e.target.checked)}
          checked={OKVEDChanged}
          className={sf("visibility-checkbox")}
          disabled={disabled}
        >
          Изменение видов деятельности
        </Checkbox>
        <Tooltip title={errorsTypes.requiredChanges} visible={Boolean(changeOKVEDError)} />
      </div>
    );
  };

  renderActivity = () => {
    const { OKVEDChanged, scene, disabled } = this.props;
    // просто disabled={disabled} у ActivityForm почему-то не работает
    const activityDisabledKostil = {
      disabled,
    };

    return (
      <div style={{ display: OKVEDChanged ? "block" : "none" }}>
        <ActivityForm
          {...activityDisabledKostil}
          disabled={disabled}
          scene={scene}
          showTitle={false}
          mode="holdOriginal"
        />
      </div>
    );
  };

  renderFilesForm = () => {
    const { scene, loading, wasLoaded, firstOtherFile, otherFiles, addOtherFile, loadedOtherFilesCount } = this.props;

    const otherFilesFields: IFileField[] = [];
    // Если загружен файл 'file_drug', то отображаем его только на этапе
    // после отправки в ФНС
    if (firstOtherFile && (isScene("Регистрация в ФНС", scene) || isScene("Зарегистрирован", scene))) {
      otherFilesFields.push({
        attribute: attributes["Другое"],
        title: "Другое",
        label: "Другое",
        fileType: "uploadIP_EDIT",
      });
    }

    if (otherFiles.length > 0) {
      otherFiles.forEach((file) => {
        otherFilesFields.push({
          attribute: file,
          title: "Другое",
          label: "Другое",
          fileType: "uploadIP_EDIT",
        });
      });
    }
    if (otherFiles.length === loadedOtherFilesCount) {
      addOtherFile(attributes["Другое"]);
    }

    return (
      <div>
        <FilesForm
          fileFields={mainFields.concat(otherFilesFields)}
          disabled={!isScene("Создание ссылки", scene)}
          disabledTooltipVisible={isScene("Заполнение", scene)}
          loading={loading}
          wasLoaded={wasLoaded}
        />
        {scene && isScene("Выпущена КЭП", scene) && (
          <>
            <Divider className={sf("files-divider")} />
            <FilesForm
              fileFields={additionalFields}
              disabled={isScene("Зарегистрирован", scene) || isScene("Регистрация в ФНС", scene)}
              disabledTooltipVisible={isScene("Заполнение", scene)}
              showTitle={false}
              loading={loading}
              wasLoaded={wasLoaded}
            />
          </>
        )}
      </div>
    );
  };

  render() {
    const { formattedEntry, disabled } = this.props;

    return (
      <div className={`${sf({ "with-files": true })} ${b()}`}>
        <div className={sf("forms")}>
          <INNandOGRNForm disabled={disabled} type={entriesTypes["ИзмененияИП"]} data={formattedEntry} />

          <Divider />

          <h2 className={sf("title")}>Данные заявителя</h2>
          {this.renderPreview()}
          {this.renderModal()}
          <DocsFormatForm disabled={disabled} data={formattedEntry} className={b("docs-form")} />

          <Divider />

          {this.renderCheckbox()}
          {this.renderActivity()}
        </div>

        {this.renderFilesForm()}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  const { loading, wasLoaded, formattedEntry, validateErrors } = state.entries;
  const userCompanyId = state?.userReducer?.user?.company?.id;
  const passportValues = formValueSelector("passport");
  const INNandOGRNValues = formValueSelector("INNandOGRN");
  const inostr = passportValues(state, attributes["иностранец"]);
  const ogrn = INNandOGRNValues(state, attributes["ОГРНИП"]);
  const changeOKVEDError = state.entries.validateErrors?.changeOKVEDError;
  const OKVEDChanged =
    //@ts-ignore
    typeof formattedEntry[attributes["изменениеОКВЭД"]] === "string"
      ? //
        // @ts-ignore
        formattedEntry[attributes["изменениеОКВЭД"]] === "true"
        ? true
        : false
      : //
        //@ts-ignore
        formattedEntry[attributes["изменениеОКВЭД"]];
  const firstOtherFile = state.files.loaded.drug;
  const otherFiles = state.files.otherFiles.files;
  const loadedOtherFilesCount = Object.entries(state.files.loaded).filter(
    ([key, value]) => key.match(/drug(13|14)?_\d/g) && typeof value !== "undefined"
  ).length;

  return {
    loading,
    wasLoaded,
    inostr,
    formattedEntry,
    ogrn,
    validateErrors,
    changeOKVEDError,
    OKVEDChanged,
    firstOtherFile,
    otherFiles,
    loadedOtherFilesCount,
    userCompanyId,
  };
};

const mapDispatchToProps = {
  changeFormattedEntryAttribute,
  updateApplicant,
  addOtherFile,
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Forms);

export { connectedComponent as Forms };
