import { userConstants } from "../user.constants";
import { endpoints } from "src/constants";
import { API, getWithExpiry } from "src/helpers";
import { logout } from "./";
import { message } from "antd";
import { isEmpty } from "lodash";
import { IUserInfo } from "src/helpers/types/User";

export const getUserInfo = () => {
  const { GET_USER_INFO_REQUEST, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE } = userConstants;
  const request = () => ({ type: GET_USER_INFO_REQUEST });
  const success = (user: IUserInfo) => ({ type: GET_USER_INFO_SUCCESS, user });
  const failure = () => ({ type: GET_USER_INFO_FAILURE });

  return async function (dispatch: Function) {
    dispatch(request());
    if (getWithExpiry("token")) {
      try {
        const userInfo: IUserInfo | string = await API.get(
          `${endpoints.whoAmI}?hash=${localStorage.getItem("browser_token")}`
        );
        if (
          isEmpty(userInfo) ||
          (typeof userInfo === "object" && userInfo["block_session"]) ||
          (typeof userInfo === "string" && userInfo === "Unauthorized")
        ) {
          dispatch(failure());
          dispatch(logout());
        } else if (typeof userInfo === "object") {
          dispatch(success(userInfo));
          // @ts-ignore
          window.carrotquest?.identify([
            { op: "update_or_create", key: "$email", value: userInfo.user?.email || "Email неизвестен" },
            { op: "update_or_create", key: "$name", value: userInfo.user?.fullName || "Имя неизвестно" },
            { op: "update_or_create", key: "$group", value: userInfo.company?.name || "Название компании неизвестно" },
          ]);
        }
      } catch (err: any) {
        message.error(`Не удалось загрузить информацию о пользователе: ${err}`);
        dispatch(failure());
      }
    } else {
      dispatch(failure());
    }
  };
};
